// * This is Where the global Styles Exist
// *i.e will show up in the dom like  body p { color: red }

export default {
  root: {
    // *body styles go here at the root level
    position: 'absolute',
    background: 'transparent',
    width: '100%',
    overflowX: 'hidden',
    '::before': {
      content: "''",
      width: '150vw',
      height: '500vh',
      position: 'fixed',
      left: '-110vw',
      top: '0rem',
      //   backgroundColor: '#c12f67',
      transform: 'rotate(28deg)',
      zIndex: '-1'
      //   backgroundImage:
      //     'url(https://www.transparenttextures.com/patterns/green-cup.png)',
    },
    margin: '0px',
    fontFamily: 'body',
    fontWeight: 'body',
    h1: {
      variant: 'text.heading',
      fontSize: '2rem'
    },
    h2: {
      variant: 'text.heading',
      fontSize: '1.75rem'
    },
    h3: {
      variant: 'text.heading',
      fontSize: '1.5rem'
    },
    h4: {
      variant: 'text.heading',
      fontSize: '1.4rem'
    },
    h5: {
      variant: 'text.heading',
      fontSize: '1.25rem'
    },
    h6: {
      variant: 'text.heading',
      fontSize: '1rem'
    },
    p: {
      variant: 'text.text'
    },
    a: {
      variant: 'text.link'
    },
    svg: {
      fill: 'inherit'
    },

    nav: {
      li: {
        a: {
          variant: 'text.navLink'
        }
      }
    }
  }
}
