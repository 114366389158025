export default {
  heading: 'Kanit, sans-serif',
  subheading: 'Kanit',
  body: 'Dosis, sans-serif',
  body2: 'Dosis, sans-serif',
  monospace: 'Dosis, monospace',
  display: 'Kanit, cursive',
  googleFonts: ['Dosis:200,300', 'Kanit:300,500'],
  customFamilies: [],
  customUrls: []
}
