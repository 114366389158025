export default {
  text: 'white',
  text2: '#111',
  primary: '#7D8143',
  secondary: '#020201',
  background: 'black',
  backgroundPrimary: '#fff',
  backgroundSecondary: '#00000087',
  backgroundTertiary: '#00000087',
  light: '#FFF',
  dark: '#111'
}
